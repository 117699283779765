<template>
  <v-container id="schauraum" tag="section">
    <base-subheading>Schauraum</base-subheading>

    <p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2672.1982263497766!2d13.747496715829765!3d47.951890772053964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4773ff15f1a001db%3A0xc5b5bcc417fe6494!2sWiesenstra%C3%9Fe%2037%2C%204812%20Wiesen!5e0!3m2!1sde!2sat!4v1648471934985!5m2!1sde!2sat"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </p>
  </v-container>
</template>

<script>
export default {
  name: "HomeSchauraum",
};
</script>
